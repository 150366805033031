body {
  font-family: 'HelviticaNue' !important;
  margin: 0;
}
@font-face {
  font-family: 'AvenirNext';
  src: url('./fonts//AvenirNext/AvenirNextLTPro-Bold.otf');
}

@font-face {
  font-family: 'HelviticaNue';
  src: url('./fonts/helveticaneue/HelveticaNeue\ Light.ttf');
}

@font-face {
  font-family: 'HelviticaNue-Bold';
  src: url('./fonts/helveticaneue/HelveticaNeue\ Medium.ttf');
}
.jodit-status-bar {
  visibility: collapse;
}
#input[type='file'] {
  display: none;
}
.image-upload {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
  background: rgb(224, 21, 162);
  border-radius: 10px;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
  background: rgb(206 26 134);
}

strong {
  font-family: HelviticaNue-Bold, AvenirNext, Roboto, sans-serif;
}
.tox:not(.tox-tinymce-inline) .tox-editor-header {
  bottom: 0px !important;
  position: absolute;
}
.Wirisformula {
  width: 500px !important;
  max-width: 1000px !important;
}

.react-calendar__decade-view .react-calendar__tile {
  padding: 10px 0 !important;
}

.react-calendar__tile--active {
  background-color: #624ba2 !important;
}

.react-calendar__tile:enabled:hover {
  background-color: #624ba2 !important;
  color: white !important;
  opacity: 0.7 !important;
}
.dragging {
  opacity: 0.6;
}

.item.dragging .details,
.item.dragging i {
  opacity: 0.8;
  transform: scale(1.02);
  background: #f0f0f0;
}

.react-date-picker {
  position: relative !important;
}

.react-date-picker__calendar {
  top: -215px !important;
}

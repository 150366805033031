.quill-send-btn {

    bottom: 1.5%;
    right: 0.5%;

  }
  .btnStyle{
    cursor: pointer;
    position: absolute;
    height: 30px;
    width: 30px;
    outline: none;
    border: none;
    background: rgb(247, 232, 245);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  .tox:not(.tox-tinymce-inline) .tox-editor-header{
    bottom: 0px !important;
    position: absolute;
  }
  .Wirisformula{
    width: 500px !important;
    max-width: 1500px !important;
  }
  .tox-statusbar__branding{
    display: none;
  }
  .container{
    height: inherit;
    position: relative;
    width: 100%;
  }
  .edit-quill-send-btn {
    bottom: 11%;
    right: 1.5%;
  
  }
  .tox .tox-editor-container{
    margin-bottom: 65px;
  }
  .tox-statusbar__path-item{
    display: none;
  }
  .button-container {
    position: relative;
    width: 100%;
    height: auto;
    background-color: tan;
  }